<template>
  <div class="project">
    <div class="project__img">
      <img :src="imgSrc" :alt="name" loading="lazy" />
    </div>

    <div class="project__content">
      <div class="project__name">{{ name }}</div>

      <template v-if="props">
        <List
          class="project__list small"
          :list="props"
        />
      </template>

      <Btn
        :text="btnName"
        @click.prevent.native="showPopup()"
        class="w-100"
      />
    </div>
  </div>
</template>

<script>
import List from '@/components/Base/List.vue';
import Btn from '@/components/Base/Btn.vue';

export default {
  name: 'ProjectItem',
  components: {
    List,
    Btn,
  },
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    props: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      btnName: 'Подробнее',
    };
  },
  methods: {
    showPopup() {
      this.$emit('showPopup');
    },
  },
};
</script>

<style lang="scss" scoped>
  .project {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(63, 63, 63, 0.1);
    border-radius: 15px;
    overflow: hidden;
    height: 100%;

    &__img {
      height: 186px;
      margin-bottom: 17px;

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      padding: 0 18px 29px;
    }

    &__name {
      font-weight: 800;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 10px;

      @media (max-width: 575px) {
        font-size: 17px;
      }
    }

    &__list {
      font-size: 14px;
      margin-bottom: 20px;

      li {
        line-height: 18px;
      }
    }
  }
</style>
