<template>
  <section class="section">
    <div class="container">
      <h2 class="section__title text-center" v-html="title"></h2>

      <div class="row d-none d-lg-flex">
        <div class="mb-4 col-lg-4 col-xl-3">
          <CatalogBlock
            :imgSrc="catalogBlock.imgSrc"
            :title="catalogBlock.title"
            :text="catalogBlock.text"
          />
        </div>

        <template v-for="(item, index) in showProjectList">
          <div class="mb-4 col-lg-4 col-xl-3" :key="index">
            <ProjectItemV2
              :imgSrc="item.imgSrc[0]"
              :name="item.name"
              :props="item.props"
              @showPopup="showProjectPopup = index"
            />
          </div>
        </template>

        <div class="col-12 text-center mt-4" v-if="showBtnMore">
          <Btn
            text="Показать еще"
            @click.prevent.native="setCountProject()"
          />
        </div>
      </div>

      <template v-for="(item, index) in projectList">
        <Popup
          :value="showProjectPopup === index"
          :small="false"
          @close="showProjectPopup = null"
          :key="`popup-project-${index}`"
        >
          <ProjectDetail
            :imgSrc="item.imgSrc"
            :title="item.title"
            :name="item.name"
            :props="item.props"
          />
        </Popup>
      </template>

      <div class="section__slider d-lg-none">
        <Slider
          :breakpoints="sliderBreakpoints"
          :id="1"
        >
          <template v-for="(item, index) in projectList">
            <SwiperSlide :key="index">
              <ProjectItemV2
                :imgSrc="item.imgSrc[0]"
                :name="item.name"
                :props="item.props"
                @showPopup="showProjectPopup = index"
              />
            </SwiperSlide>
          </template>

          <SwiperSlide>
            <CatalogBlock
              :imgSrc="catalogBlock.imgSrc"
              :title="catalogBlock.title"
              :text="catalogBlock.text"
            />
          </SwiperSlide>
        </Slider>
      </div>
    </div>
  </section>
</template>

<script>
import Slider from '@/components/Base/Slider.vue';
import { SwiperSlide } from 'vue-awesome-swiper';
import ProjectItemV2 from '@/components/Base/ProjectItemV2.vue';
import CatalogBlock from '@/components/Base/CatalogBlock.vue';
import Popup from '@/components/Base/Popup.vue';
import ProjectDetail from '@/components/Base/ProjectDetail.vue';
import Btn from '@/components/Base/Btn.vue';

export default {
  name: 'Section3v2',
  components: {
    Slider,
    SwiperSlide,
    ProjectItemV2,
    CatalogBlock,
    Popup,
    ProjectDetail,
    Btn,
  },
  data() {
    return {
      title: 'Популярные проекты 2023 года',
      sliderBreakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
      },
      projectList: [
        {
          imgSrc: [
            require('@/assets/img/projects/О-132/1.jpg'),
            require('@/assets/img/projects/О-132/2.jpg'),
            require('@/assets/img/projects/О-132/3.jpg'),
            require('@/assets/img/projects/О-132/4.jpg'),
            require('@/assets/img/projects/О-132/5.jpg'),
            require('@/assets/img/projects/О-132/6.jpg'),
          ],
          name: 'О-132',
          title: 'Проект одноэтажного дома 14 на 12 метров',
          props: [
            'Общая площадь: 132.26 м2',
            'Материал: Газобетон',
            'Число:: этажей 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-126/1.jpg'),
            require('@/assets/img/projects/О-126/2.jpg'),
            require('@/assets/img/projects/О-126/3.jpg'),
            require('@/assets/img/projects/О-126/4.jpg'),
            require('@/assets/img/projects/О-126/5.jpg'),
            require('@/assets/img/projects/О-126/6.jpg'),
          ],
          name: 'О-126',
          title: 'Проект одноэтажного дома 16 на 14 метров',
          props: [
            'Общая площадь: 126.05 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-114/1.jpg'),
            require('@/assets/img/projects/О-114/2.jpg'),
            require('@/assets/img/projects/О-114/3.jpg'),
            require('@/assets/img/projects/О-114/4.jpg'),
            require('@/assets/img/projects/О-114/5.jpg'),
            require('@/assets/img/projects/О-114/6.jpg'),
          ],
          name: 'О-114',
          title: 'Проект одноэтажного дома 13 на 11 метров',
          props: [
            'Общая площадь: 114.02 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-78/1.jpg'),
            require('@/assets/img/projects/О-78/2.jpg'),
            require('@/assets/img/projects/О-78/3.jpg'),
            require('@/assets/img/projects/О-78/4.jpg'),
            require('@/assets/img/projects/О-78/5.jpg'),
            require('@/assets/img/projects/О-78/6.jpg'),
          ],
          name: 'О-78',
          title: 'Проект одноэтажного дома 12 на 8 метров',
          props: [
            'Общая площадь: 78.8 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-113/1.jpg'),
            require('@/assets/img/projects/О-113/2.jpg'),
            require('@/assets/img/projects/О-113/3.jpg'),
            require('@/assets/img/projects/О-113/4.jpg'),
            require('@/assets/img/projects/О-113/5.jpg'),
            require('@/assets/img/projects/О-113/6.jpg'),
          ],
          name: 'О-113',
          title: 'Проект одноэтажного дома 14 на 10 метров',
          props: [
            'Общая площадь: 113.1 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-111/1.jpg'),
            require('@/assets/img/projects/О-111/2.jpg'),
            require('@/assets/img/projects/О-111/3.jpg'),
            require('@/assets/img/projects/О-111/4.jpg'),
            require('@/assets/img/projects/О-111/5.jpg'),
            require('@/assets/img/projects/О-111/6.jpg'),
          ],
          name: 'О-111',
          title: 'Проект одноэтажного дома 12 на 12 метров',
          props: [
            'Общая площадь: 111.3 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-113-2/1.jpg'),
            require('@/assets/img/projects/О-113-2/2.jpg'),
            require('@/assets/img/projects/О-113-2/3.jpg'),
            require('@/assets/img/projects/О-113-2/4.jpg'),
            require('@/assets/img/projects/О-113-2/5.jpg'),
            require('@/assets/img/projects/О-113-2/6.jpg'),
          ],
          name: 'О-113-2',
          title: 'Проект одноэтажного дома 15 на 11 метров',
          props: [
            'Общая площадь: 113 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-129/1.jpg'),
            require('@/assets/img/projects/О-129/2.jpg'),
            require('@/assets/img/projects/О-129/3.jpg'),
            require('@/assets/img/projects/О-129/4.jpg'),
            require('@/assets/img/projects/О-129/5.jpg'),
            require('@/assets/img/projects/О-129/6.jpg'),
          ],
          name: 'О-129',
          title: 'Проект одноэтажного дома 16 на 12 метров',
          props: [
            'Общая площадь: 129.2 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-128/1.jpg'),
            require('@/assets/img/projects/О-128/2.jpg'),
            require('@/assets/img/projects/О-128/3.jpg'),
            require('@/assets/img/projects/О-128/4.jpg'),
            require('@/assets/img/projects/О-128/5.jpg'),
            require('@/assets/img/projects/О-128/6.jpg'),
          ],
          name: 'О-128',
          title: 'Проект одноэтажного дома 13 на 13 метров',
          props: [
            'Общая площадь: 128.1 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-85/1.jpg'),
            require('@/assets/img/projects/О-85/2.jpg'),
            require('@/assets/img/projects/О-85/3.jpg'),
            require('@/assets/img/projects/О-85/4.jpg'),
            require('@/assets/img/projects/О-85/5.jpg'),
            require('@/assets/img/projects/О-85/6.jpg'),
          ],
          name: 'О-85',
          title: 'Проект одноэтажного дома 13 на 9 метров',
          props: [
            'Общая площадь: 85.4 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-115/1.jpg'),
            require('@/assets/img/projects/ОМ-115/2.jpg'),
            require('@/assets/img/projects/ОМ-115/3.jpg'),
            require('@/assets/img/projects/ОМ-115/4.jpg'),
            require('@/assets/img/projects/ОМ-115/5.jpg'),
            require('@/assets/img/projects/ОМ-115/6.jpg'),
            require('@/assets/img/projects/ОМ-115/7.jpg'),
          ],
          name: 'ОМ-115',
          title: 'Проект дома с мансардой 9 на 9 метров',
          props: [
            'Общая площадь: 115.04 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-101/1.jpg'),
            require('@/assets/img/projects/ОМ-101/2.jpg'),
            require('@/assets/img/projects/ОМ-101/3.jpg'),
            require('@/assets/img/projects/ОМ-101/4.jpg'),
            require('@/assets/img/projects/ОМ-101/5.jpg'),
            require('@/assets/img/projects/ОМ-101/6.jpg'),
            require('@/assets/img/projects/ОМ-101/7.jpg'),
          ],
          name: 'ОМ-101',
          title: 'Проект дома с мансардой 13 на 10 метров',
          props: [
            'Общая площадь: 101.8 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-93/1.jpg'),
            require('@/assets/img/projects/ОМ-93/2.jpg'),
            require('@/assets/img/projects/ОМ-93/3.jpg'),
            require('@/assets/img/projects/ОМ-93/4.jpg'),
            require('@/assets/img/projects/ОМ-93/5.jpg'),
            require('@/assets/img/projects/ОМ-93/6.jpg'),
            require('@/assets/img/projects/ОМ-93/7.jpg'),
          ],
          name: 'ОМ-93',
          title: 'Проект дома с мансардой 9 на 8 метров',
          props: [
            'Общая площадь: 93.1 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-100/1.jpg'),
            require('@/assets/img/projects/ОМ-100/2.jpg'),
            require('@/assets/img/projects/ОМ-100/3.jpg'),
            require('@/assets/img/projects/ОМ-100/4.jpg'),
            require('@/assets/img/projects/ОМ-100/5.jpg'),
            require('@/assets/img/projects/ОМ-100/6.jpg'),
            require('@/assets/img/projects/ОМ-100/7.jpg'),
          ],
          name: 'ОМ-100',
          title: 'Проект дома с мансардой 10 на 9 метров',
          props: [
            'Общая площадь: 100.44 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-105/1.jpg'),
            require('@/assets/img/projects/ОМ-105/2.jpg'),
            require('@/assets/img/projects/ОМ-105/3.jpg'),
            require('@/assets/img/projects/ОМ-105/4.jpg'),
            require('@/assets/img/projects/ОМ-105/5.jpg'),
            require('@/assets/img/projects/ОМ-105/6.jpg'),
          ],
          name: 'ОМ-105',
          title: 'Проект дома с мансардой 15 на 10 метров',
          props: [
            'Общая площадь: 105.66 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-130/1.jpg'),
            require('@/assets/img/projects/ОМ-130/2.jpg'),
            require('@/assets/img/projects/ОМ-130/3.jpg'),
            require('@/assets/img/projects/ОМ-130/4.jpg'),
            require('@/assets/img/projects/ОМ-130/5.jpg'),
            require('@/assets/img/projects/ОМ-130/6.jpg'),
            require('@/assets/img/projects/ОМ-130/7.jpg'),
          ],
          name: 'ОМ-130',
          title: 'Проект дома с мансардой 12 на 8 метров',
          props: [
            'Общая площадь: 130.1 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-119/1.jpg'),
            require('@/assets/img/projects/ОМ-119/2.jpg'),
            require('@/assets/img/projects/ОМ-119/3.jpg'),
            require('@/assets/img/projects/ОМ-119/4.jpg'),
            require('@/assets/img/projects/ОМ-119/5.jpg'),
            require('@/assets/img/projects/ОМ-119/6.jpg'),
            require('@/assets/img/projects/ОМ-119/7.jpg'),
          ],
          name: 'ОМ-119',
          title: 'Проект дома с мансардой 9 на 10 метров',
          props: [
            'Общая площадь: 119.14 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-119-2/1.jpg'),
            require('@/assets/img/projects/ОМ-119-2/2.jpg'),
            require('@/assets/img/projects/ОМ-119-2/3.jpg'),
            require('@/assets/img/projects/ОМ-119-2/4.jpg'),
            require('@/assets/img/projects/ОМ-119-2/5.jpg'),
            require('@/assets/img/projects/ОМ-119-2/6.jpg'),
            require('@/assets/img/projects/ОМ-119-2/7.jpg'),
          ],
          name: 'ОМ-119-2',
          title: 'Проект дома с мансардой 12 на 7 метров',
          props: [
            'Общая площадь: 119.9 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-120/1.jpg'),
            require('@/assets/img/projects/ОМ-120/2.jpg'),
            require('@/assets/img/projects/ОМ-120/3.jpg'),
            require('@/assets/img/projects/ОМ-120/4.jpg'),
            require('@/assets/img/projects/ОМ-120/5.jpg'),
            require('@/assets/img/projects/ОМ-120/6.jpg'),
            require('@/assets/img/projects/ОМ-120/7.jpg'),
          ],
          name: 'ОМ-120',
          title: 'Проект дома с мансардой 10 на 9 метров',
          props: [
            'Общая площадь: 120.5 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-133/1.jpg'),
            require('@/assets/img/projects/ОМ-133/2.jpg'),
            require('@/assets/img/projects/ОМ-133/3.jpg'),
            require('@/assets/img/projects/ОМ-133/4.jpg'),
            require('@/assets/img/projects/ОМ-133/5.jpg'),
            require('@/assets/img/projects/ОМ-133/6.jpg'),
            require('@/assets/img/projects/ОМ-133/7.jpg'),
          ],
          name: 'ОМ-133',
          title: 'Проект дома с мансардой 11 на 12 метров',
          props: [
            'Общая площадь: 133.5 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ДГ-82/1.jpg'),
            require('@/assets/img/projects/ДГ-82/2.jpg'),
            require('@/assets/img/projects/ДГ-82/3.jpg'),
            require('@/assets/img/projects/ДГ-82/4.jpg'),
            require('@/assets/img/projects/ДГ-82/5.jpg'),
            require('@/assets/img/projects/ДГ-82/6.jpg'),
            require('@/assets/img/projects/ДГ-82/7.jpg'),
          ],
          name: 'ДГ-82',
          title: 'Проект двухэтажного дома 8 на 9 метров',
          props: [
            'Общая площадь: 82.64 м2',
            'Материал: Газобетон',
            'Число этажей: 2',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ДГ-129/1.jpg'),
            require('@/assets/img/projects/ДГ-129/2.jpg'),
            require('@/assets/img/projects/ДГ-129/3.jpg'),
            require('@/assets/img/projects/ДГ-129/4.jpg'),
            require('@/assets/img/projects/ДГ-129/5.jpg'),
            require('@/assets/img/projects/ДГ-129/6.jpg'),
            require('@/assets/img/projects/ДГ-129/7.jpg'),
          ],
          name: 'ДГ-129',
          title: 'Проект двухэтажного дома 12 на 12 метров',
          props: [
            'Общая площадь: 129.4 м2',
            'Материал: Газобетон',
            'Число этажей: 2',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/Д-132/1.jpg'),
            require('@/assets/img/projects/Д-132/2.jpg'),
            require('@/assets/img/projects/Д-132/3.jpg'),
            require('@/assets/img/projects/Д-132/4.jpg'),
            require('@/assets/img/projects/Д-132/5.jpg'),
            require('@/assets/img/projects/Д-132/6.jpg'),
            require('@/assets/img/projects/Д-132/7.jpg'),
          ],
          name: 'Д-132',
          title: 'Проект двухэтажного дома 10 на 9 метров',
          props: [
            'Общая площадь: 132.5 м2',
            'Материал: Газобетон',
            'Число этажей: 2',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/Д-129/1.jpg'),
            require('@/assets/img/projects/Д-129/2.jpg'),
            require('@/assets/img/projects/Д-129/3.jpg'),
            require('@/assets/img/projects/Д-129/4.jpg'),
            require('@/assets/img/projects/Д-129/5.jpg'),
            require('@/assets/img/projects/Д-129/6.jpg'),
            require('@/assets/img/projects/Д-129/7.jpg'),
          ],
          name: 'Д-129',
          title: 'Проект двухэтажного дома 16 на 9 метров',
          props: [
            'Общая площадь: 129.8 м2',
            'Материал: Газобетон',
            'Число этажей: 2',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/Д-127/1.jpg'),
            require('@/assets/img/projects/Д-127/2.jpg'),
            require('@/assets/img/projects/Д-127/3.jpg'),
            require('@/assets/img/projects/Д-127/4.jpg'),
            require('@/assets/img/projects/Д-127/5.jpg'),
            require('@/assets/img/projects/Д-127/6.jpg'),
            require('@/assets/img/projects/Д-127/7.jpg'),
          ],
          name: 'Д-127',
          title: 'Проект двухэтажного дома 8 на 11 метров',
          props: [
            'Общая площадь: 127.87 м2',
            'Материал: Газобетон',
            'Число этажей: 2',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/Д-119/1.jpg'),
            require('@/assets/img/projects/Д-119/2.jpg'),
            require('@/assets/img/projects/Д-119/3.jpg'),
            require('@/assets/img/projects/Д-119/4.jpg'),
            require('@/assets/img/projects/Д-119/5.jpg'),
            require('@/assets/img/projects/Д-119/6.jpg'),
            require('@/assets/img/projects/Д-119/7.jpg'),
          ],
          name: 'Д-119',
          title: 'Проект двухэтажного дома 10 на 9 метров',
          props: [
            'Общая площадь: 119.4 м2',
            'Материал: Газобетон',
            'Число этажей: 2',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОГ-120/1.jpg'),
            require('@/assets/img/projects/ОГ-120/2.jpg'),
            require('@/assets/img/projects/ОГ-120/3.jpg'),
            require('@/assets/img/projects/ОГ-120/4.jpg'),
            require('@/assets/img/projects/ОГ-120/5.jpg'),
            require('@/assets/img/projects/ОГ-120/6.jpg'),
          ],
          name: 'ОГ-120',
          title: 'Проект одноэтажного дома 15 на 11 метров',
          props: [
            'Общая площадь: 120.4 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОГ-124/1.jpg'),
            require('@/assets/img/projects/ОГ-124/2.jpg'),
            require('@/assets/img/projects/ОГ-124/3.jpg'),
            require('@/assets/img/projects/ОГ-124/4.jpg'),
            require('@/assets/img/projects/ОГ-124/5.jpg'),
            require('@/assets/img/projects/ОГ-124/6.jpg'),
          ],
          name: 'ОГ-124',
          title: 'Проект одноэтажного дома 12 на 16 метров',
          props: [
            'Общая площадь: 124.94 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/О-109/1.jpg'),
            require('@/assets/img/projects/О-109/2.jpg'),
            require('@/assets/img/projects/О-109/3.jpg'),
            require('@/assets/img/projects/О-109/4.jpg'),
            require('@/assets/img/projects/О-109/5.jpg'),
            require('@/assets/img/projects/О-109/6.jpg'),
          ],
          name: 'О-109',
          title: 'Проект одноэтажного дома 15 на 9 метров',
          props: [
            'Общая площадь: 109.8 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: нет',
            'Цоколь: нет',
          ],
        },
        {
          imgSrc: [
            require('@/assets/img/projects/ОМ-123/1.jpg'),
            require('@/assets/img/projects/ОМ-123/2.jpg'),
            require('@/assets/img/projects/ОМ-123/3.jpg'),
            require('@/assets/img/projects/ОМ-123/4.jpg'),
            require('@/assets/img/projects/ОМ-123/5.jpg'),
            require('@/assets/img/projects/ОМ-123/6.jpg'),
          ],
          name: 'ОМ-123',
          title: 'Проект дома с мансардой 14 на 8 метров',
          props: [
            'Общая площадь: 123.5 м2',
            'Материал: Газобетон',
            'Число этажей: 1',
            'Мансарда: есть',
            'Цоколь: нет',
          ],
        },
      ],
      catalogBlock: {
        imgSrc: require('@/assets/img/projects/catalog.webp'),
        title: 'Дом Вашей мечты',
        text: 'Не можете найти дом, удовлетворяющий всем требованиям? Мы поможем создать планировку Вашей мечты.',
      },
      showProjectPopup: null,
      popupTitle: 'Возможные планировки дома',
      countProject: 7,
    };
  },
  computed: {
    disablePopupSpecial() {
      return this.$store.getters.getDisablePopupSpecial;
    },
    showProjectList() {
      return this.projectList.slice(0, this.countProject);
    },
    showBtnMore() {
      return this.projectList.length > this.countProject;
    },
  },
  methods: {
    scrollPage() {
      const section = document.getElementById('projects');
      const sectionBox = section.getBoundingClientRect();
      const disablePopupSpecialStorage = sessionStorage.getItem('disablePopupSpecial');

      if (sectionBox.top <= 100 && !this.disablePopupSpecial && !disablePopupSpecialStorage) {
        this.$store.dispatch('setShowPopupSpecial', true);
        window.removeEventListener('scroll', this.scrollPage, { passive: true });
      }
    },
    setCountProject() {
      this.countProject += 4;
    },
  },
  created() {
    // this.$store.dispatch('setShowPopupSpecial', true);
    window.addEventListener('scroll', this.scrollPage, { passive: true });
  },
};
</script>

<style lang="scss" scoped>
  .section {
    background-color: #FBFBFB;
    background-image: url(~@/assets/img/block3/bg-left.webp),
      url(~@/assets/img/block3/bg-right.webp);
    background-repeat: no-repeat;
    background-position: 37px 50%, calc(100% - 145px) 95px;
    padding: 92px 0;

    &__title {
      margin-bottom: 28px;
    }

    &__slider {

      @media (max-width: 575px) {
        margin-bottom: 40px;
      }
    }

    @media (max-width: 991px) {
      background-image: url(~@/assets/img/block3/bg-left.webp),
        none;
    }

    @media (max-width: 575px) {
      padding: 40px 0;
    }
  }
</style>
