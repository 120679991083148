<template>
  <section class="section">
    <div class="container">
      <h2 class="section__title text-center" v-html="title"></h2>

      <div class="section__faq">
        <template v-for="(item, index) in faqList">
          <FaqItem
            :key="index"
            :title="item.title"
            :text="item.text"
            :active="item.active"
          />
        </template>
      </div>

      <FormBlock2
        :bgSrc="formBlock.bgSrc"
        :imgSrc="formBlock.imgSrc"
        :title="formBlock.title"
        :text="formBlock.text"
        :formProps="formBlock.formProps"
        :hint="formBlock.hint"
      />
    </div>
  </section>
</template>

<script>
import FaqItem from '@/components/Base/FaqItem.vue';
import FormBlock2 from '@/components/Base/FormBlock2.vue';

export default {
  name: 'Section8',
  components: {
    FaqItem,
    FormBlock2,
  },
  data() {
    return {
      title: 'Остались вопросы?',
      faqList: [
        {
          title: `
            Многие эксперты называют газобетон лучшим материалом для строительства загородных домов.
            Вы согласны с этим утверждением и почему?
          `,
          text: `
            С этим утверждением трудно не согласиться, так как в течении многих лет газобетон лидирует
            по запросам среди Заказчиков строительства. Газобетон практически не имеет недостатков в
            последующей эксплуатации, за что получает положительные отзывы от людей проживающих в доме
            не первый год, а также газобетон прост в процессе работы и кладки, за что получает высокие
            отзывы от производителей работ.
          `,
          active: false,
        },
        {
          title: 'Строить дома из газобетона дешевле, чем строить дома из кирпича – это правда?',
          text: `
            Строительство домов из газобетона значительно выгоднее чем из кирпича, учитывая что нормы по
            тепловой защите здания увеличиваются в разы. Стена из газосиликатного блока к примеру 350 мм
            не требует дополнительного утепления, а стена из кирпича толщиной 380 мм обязательно требует
            утепления. Работы по кладке блока действительно дешевле чем кирпича, т.к. один блок может
            заменить больше десяти кирпичей.
          `,
          active: false,
        },
        {
          title: 'Есть ли недостатки у домов из камня?',
          text: `
            Если говорить о недостатках каменного домостроения, то их найти в принципе сложно. Он не из
            дерева, как многое хотят, наверное для нас это единственный видимый недостаток.
          `,
          active: false,
        },
        {
          title: 'Какими преимуществами обладают дома из камня?',
          text: `
            Основное преимущество дома из камня - это долговечность, которая как раз необходима настоящему
            дому, так же нужно отметить что дом из камня остывает гораздо дольше чем например каркасный дом,
            это происходит из-за того что каменные стены аккумулируют тепло.
          `,
          active: false,
        },
        {
          title: 'Нужно ли утеплять стены из газобетона?',
          text: `
            Все зависит от толщины используемого блока. Газоблок толщиной 350 мм и толще не требует
            дополнительного утепления.
          `,
          active: false,
        },
        {
          title: 'Что будет с газобетонными блоками при попадании влаги?',
          text: `
            Незащищенные отделочными материалами Газобетонные блоки при попадании любой влаги намокают,
            затем в течение нескольких дней без дождя и снега высыхают до первоначального состояния.
          `,
          active: false,
        },
        {
          title: 'Огнеустойчив ли газобетон?',
          text: 'Согласно ГОСТу 30244-94, газобетон относится к негорючим материалам (НГ).',
          active: false,
        },
        {
          title: `
            Сколько по времени занимает строительство стандартного дома из камня с двумя этажами и
            площадью около 150 м²?
          `,
          text: `
            Срок строительства зависит от конструктива и технологии. В среднем, коробка дома (фундамент,
            стены, кровля) строится 3 месяца.
          `,
          active: false,
        },
        {
          title: 'В чем отличие газосиликатных блоков от газобетонных?',
          text: `
            Для ответа на данный вопрос можно обратиться к ГОСТу 31359-2007 и в нем посмотреть классификацию
            ячеистых бетонов. Можно сделать вывод, что газосиликата как материала не существует, но есть
            газобетонный блок, иными словами это один и тот же материал.
          `,
          active: false,
        },
      ],
      formBlock: {
        bgSrc: require('@/assets/img/formBlock2/bg.jpg'),
        imgSrc: require('@/assets/img/formBlock2/img.webp'),
        title: 'Не нашли ответ на интересующий Вас вопрос?',
        text: 'Оставьте номер телефона, мы свяжемся с Вами и ответим на все вопросы',
        formProps: {
          items: [
            {
              name: 'phone',
              placeholder: 'Ваш телефон',
              required: true,
              type: 'tel',
              value: '',
              mask: '+7 (999) 999-99-99',
              pattern: '^\\+7\\s\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{2}-[0-9]{2}$',
              title: '+7 (___) ___-__-__',
            },
          ],
          btnName: 'Есть вопросы',
          id: 'faq',
        },
        hint: 'Вы можете задать любой вопрос по строительству домов. Это ни к чему не обязывает.',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
  .section {
    background-color: #FBFBFB;
    background-image: url(~@/assets/img/block8/bg-left-1.webp),
      url(~@/assets/img/block8/bg-left-2.webp);
    background-repeat: no-repeat;
    background-position: calc(100% - 55px) 15px, calc(100% - 30px) 535px;
    padding: 92px 0;

    &__title {
      margin-bottom: 34px;
    }

    &__faq {
      margin-bottom: 71px;
    }

    @media (max-width: 991px) {
      background-image: none,
        url(~@/assets/img/block8/bg-left-2.webp);
    }

    @media (max-width: 575px) {
      padding: 40px 0;
    }
  }
</style>
