<template>
  <div class="userconsent">
    <input
      type="checkbox"
      :checked="value"
      :id="id"
      @input="updateInput($event.target.checked)"
    />

    <label :for="id">
      <span>
        Я согласен с
        <a href="#" @click.prevent="$store.dispatch('setShowPopupPolitic', true)">
          Политикой конфиденциальности
        </a>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'UserConsent',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    updateInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
  .userconsent {
    margin-top: 17px;

    input {
      display: none;

      &:checked + label:before {
        background-image: url(~@/assets/img/form/checkbox.svg);
      }
    }

    label {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 17px;
      color: #737373;
      display: inline-flex;
      text-align: left;

      &:before {
        content: '';
        width: 15px;
        height: 15px;
        background-color: #fff;
        border: 1px solid #D7DBDD;
        border-radius: 1px;
        flex-shrink: 0;
        margin-right: 10px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
</style>
