<template>
  <div class="special-block">
    <h2 class="text-center" v-html="title"></h2>

    <div class="text-center">
      <div class="special-block__name">{{ name }}</div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="special-block__slider">
          <Slider
            :breakpoints="sliderBreakpoints"
            :id="7"
          >
            <template v-for="(item, index) in imgSrc">
              <SwiperSlide :key="index">
                <div class="special-block__img">
                  <img :src="item" alt="special" loading="lazy" />
                </div>
              </SwiperSlide>
            </template>
          </Slider>
        </div>
      </div>

      <div class="col-md-6 align-self-center">
        <div class="special-block__text"><b>Характеристики</b></div>

        <List :list="props" class="special-block__list" />
      </div>
    </div>

    <FormBlock4 :formProps="formProps" />
  </div>
</template>

<script>
import Slider from '@/components/Base/Slider.vue';
import { SwiperSlide } from 'vue-awesome-swiper';
import List from '@/components/Base/List.vue';
import FormBlock4 from '@/components/Base/FormBlock4.vue';

export default {
  name: 'ProjectDetail',
  components: {
    Slider,
    SwiperSlide,
    List,
    FormBlock4,
  },
  props: {
    imgSrc: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    props: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      formProps: {
        items: [
          {
            name: 'phone',
            placeholder: 'Ваш телефон',
            required: true,
            type: 'tel',
            value: '',
            mask: '+7 (999) 999-99-99',
            pattern: '^\\+7\\s\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{2}-[0-9]{2}$',
            title: '+7 (___) ___-__-__',
          },
        ],
        btnName: 'Запросить смету',
        id: 'special',
      },
      sliderBreakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
  .special-block {

    h2 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 14px;

      @media (max-width: 575px) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    &__name {
      display: inline-block;
      font-family: Montserrat;
      font-weight: bold;
      font-size: 19px;
      line-height: 30px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
      background: #F3AF01;
      position: relative;
      padding: 10px 5px;
      margin: 0 15px 26px;
      text-decoration: none;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;

        @media (max-width: 575px) {
          display: none;
        }
      }

      &:before {
        border-bottom: 50px solid #F3AF01;
        border-left: 15px solid transparent;
        left: -15px;
        top: 0;
      }

      &:after {
        border-top: 50px solid #F3AF01;
        border-right: 15px solid transparent;
        right: -15px;
        top: 0;
      }

      @media (max-width: 575px) {
        width: 100%;
        margin: 0 0 26px;
      }
    }

    &__slider {
      padding-bottom: 60px;
    }

    &__img {
      height: 284px;

      img {
        object-fit: scale-down;
        object-position: center;
        width: 100%;
        height: 100%;
      }

      @media (max-width: 575px) {
        height: 200px;
      }
    }

    &__text {
      margin-bottom: 10px;
    }

    &__list {
      margin-bottom: 60px;
    }
  }
</style>
